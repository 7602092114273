<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">构建体系树</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        将文件与文件之间的关系梳理清楚，形成一张文件关系网，起到同步更改的作用
      </p>
      <br />

      <p class="PTitle">解决客户的什么问题：</p>
      <p class="SmallP">
        文件与文件之间的关系没有理清
        当一份文件更改后，相关的平级文件和下级文件没有人同步更新，导致文件与文件之间的内容脱节
      </p>
      <br />
      <p class="PTitle">我们公司的解决方案：</p>
      <p class="SmallP">
        提供文件构建辅导和培训，可在线可线下 教你如何搭建体系树
        提供软件平台，将体系树录入系统中，同步更新
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>